import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Actions from "../actions";
import DTButton from "../common/theme/DTButton";

import moment from "moment";

import CancelSubscriptionDialog from "../components/CancelSubscriptionConfirmation";
import UserProfileSettingsDialog from "../components/UserProfileSettingsDialog";
import UserProfilePreferencesDialog from "../components/UserProfilePreferencesDialog";
import UserUpdatePassword from "../components/UserUpdatePassword";
import MessageDialog from "../components/MessageDialog";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function AppHeaderProfileMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [resellerDialog, setResellerDialog] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [updatePasswordOpen, setUpdatePasswordOpen] = React.useState(false);
  const [preferencesOpen, setPreferencesOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const user = useSelector((state) => state.user);

  const stripe_selling = useSelector((state) => state.stripe_selling);

  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCancelSubscriptionDialogClose = (data) => {
    setCancelSubscriptionDialogOpen(false);
    if (data.cancellation === true) setMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
  };

  const handleSettingsDialogClose = () => {
    setSettingsOpen(false);
  };

  const handleUpdatePasswordClose = () => {
    setUpdatePasswordOpen(false);
  };

  const handlePreferencesDialogClose = () => {
    setPreferencesOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (user.attributes.sellerid) dispatch(Actions.updateCollectionProductsAndPrices());
  }, [open]);

  React.useEffect(() => {
    if (!user.info.hasOwnProperty("preferencesCategory")) setPreferencesOpen(true);
  }, []);

  const profilePicture = user?.info?.profilePicture || user?.info?.googlePicture || null;

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div
          className="bg-blue-dark border"
          style={{
            width: 30,
            height: 30,
            background: profilePicture ? 'url("' + profilePicture + '") no-repeat center center' : "",
            backgroundSize: "30px 30px",
            borderRadius: "100%",
            display: "inline-block",
          }}
        ></div>
      </Button>
      <Popper
        style={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem>{props.username}</MenuItem>
                  <MenuItem>
                    <span className="flex flex-1 flex-wrap">
                      <span className=" w-full">Plan: {props.plan_name}</span>
                      {props.cancel_at && props.plan_name !== "Free" ? (
                        <span style={{ color: "rgb(255, 98, 60)" }} className=" w-full text-sm">
                          (cancels {moment(props.cancel_at).fromNow()})
                        </span>
                      ) : null}
                    </span>
                  </MenuItem>

                  <MenuItem>
                    Quota usage: {props.quota_usage} / {props.monthly_quota}{" "}
                  </MenuItem>
                  {props.credits > 0 && <MenuItem>Slide credits: {props.credits}</MenuItem>}
                  {props.monthly_synthesia > 0 ? (
                    <MenuItem>
                      Quota virtual presenter usage: {Math.round(parseFloat(props.synthesia_usage / 60) * 100) / 100} /{" "}
                      {Math.round(parseFloat(props.monthly_synthesia / 60) * 100) / 100}{" "}
                    </MenuItem>
                  ) : null}
                  <MenuItem>
                    Credits: {Math.round(parseFloat(props.virtual_presenter_credits / 60) * 100) / 100} (=minutes for
                    virtual presenter)
                  </MenuItem>
                  {user.attributes.stripe_customer_id ? (
                    <MenuItem
                      onClick={() => {
                        setOpen(false);
                        props.history.push("/manage_subscriptions?path=" + window.location.pathname);
                      }}
                    >
                      Manage Subscriptions
                    </MenuItem>
                  ) : null}
                  {!user.attributes.stripe_customer_id && props.plan_name !== "Free" ? (
                    <MenuItem
                      onClick={() => {
                        setCancelSubscriptionDialogOpen(true);
                      }}
                    >
                      Cancel Subscription
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      setUpdatePasswordOpen(true);
                    }}
                  >
                    Update Password
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      setSettingsOpen(true);
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      setPreferencesOpen(true);
                    }}
                  >
                    User Preferences
                  </MenuItem>
                  {props.plan_name.toLowerCase().indexOf("professional") != -1 ||
                  props.plan_name.toLowerCase().indexOf("admin") != -1 ||
                  props.plan_name.toLowerCase().indexOf("business") != -1 ? (
                    <MenuItem>
                      <BecomeReseller
                        sellerUrl={user.seller_url}
                        setResellerDialog={setResellerDialog}
                        resellerDialog={resellerDialog}
                        attributes={user.attributes}
                        stripe_selling={stripe_selling}
                      />
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={props.logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CancelSubscriptionDialog open={cancelSubscriptionDialogOpen} handleClose={handleCancelSubscriptionDialogClose} />
      <MessageDialog
        title="Processing request"
        message="Thank you, your cancellation request is being processed."
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
      <UserUpdatePassword open={updatePasswordOpen} handleClose={handleUpdatePasswordClose} />
      <UserProfileSettingsDialog open={settingsOpen} handleClose={handleSettingsDialogClose} />
      <UserProfilePreferencesDialog open={preferencesOpen} handleClose={handlePreferencesDialogClose} />
    </div>
  );
}

function BecomeReseller(props) {
  const [becomeResellerWindow, setBecomeResellerWindow] = React.useState(props.open);
  const [sellerUrl, setSellerUrl] = React.useState(props.sellerUrl);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setBecomeResellerWindow(true);
  };
  useEffect(() => {
    if (props.sellerUrl) window.open(props.sellerUrl.url);
  }, [props.sellerUrl]);

  return (
    <React.Fragment>
      <span
        onClick={() => {
          props.setResellerDialog(true);
        }}
      >
        {props.stripe_selling && props.stripe_selling.seller && props.stripe_selling.seller.enabled ? (
          <React.Fragment>Update seller registration</React.Fragment>
        ) : (
          <React.Fragment>Register as a content seller</React.Fragment>
        )}
      </span>
      <Dialog
        open={props.resellerDialog ? props.resellerDialog : false}
        onClose={() => {
          props.setResellerDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Register as a content seller</DialogTitle>
        <DialogContent>
          <div style={{ padding: 10 }}>
            <strong>automate.video</strong> has established a partnership with <strong>stripe.com</strong> to allow you
            to sell your content.
          </div>
          <div style={{ padding: 10 }}>
            You are free to establish your pricing strategy, whether on <strong>automate.video</strong> or on stripe for
            more advanced pricing strategies (trial period, etc...)
          </div>
          <div style={{ padding: 10 }}>
            To register as a seller, you will need to provide Stripe with information about you and financial
            informations to receive funds. You will be redirected to <strong>stripe.com</strong> website to create your
            account and complete your registration.
          </div>
          <div style={{ padding: 10 }}>Here is a summary of the platform charges when selling content.</div>
          <div style={{ padding: 10 }}>The costs are calculated per payout</div>
          <div style={{ fontSize: "1.5em", paddingLeft: 10 }}>1.4% + 0.25 € for european cards</div>
          <div style={{ fontSize: "1.5em", paddingLeft: 10 }}>2.9% + 0.25 € for non european cards</div>
          {sellerUrl ? (
            <DTButton
              onClick={() => {
                window.open(sellerUrl.url);
              }}
            >
              Click to go to seller registration
            </DTButton>
          ) : null}
        </DialogContent>
        <DialogActions>
          <DTButton
            onClick={() => {
              props.setResellerDialog(false);
            }}
            primary
          >
            CLOSE
          </DTButton>
          <DTButton
            onClick={() => {
              dispatch(Actions.createSeller());
            }}
            primary
            style={{ color: "white", backgroundColor: "darkgreen" }}
          >
            {props.attributes.sellerid ? "CONTINUE REGISTRATION" : "START REGISTRATION"}
          </DTButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
