export default (state = { processing: false, actions: [], deleted: [] }, payload) => {
  let newState = {};
  switch (payload.type) {
    case "EDIT_PLACEHOLDER_FAILED":
      newState = { processing: false };
      let actions = [...state.actions];
      actions.pop();
      if (actions.length > 0) {
        newState.actions = actions;
        newState.processing = true;
      } else {
        newState.actions = [];
      }
      return newState;
    case "PREVIEW_AVATAR_SUCCESS":
      return {
        ...state,
        processing: false,
        progress: null,
        previewImages: JSON.parse(payload.data),
      };
    case "PREVIEW_AVATAR_FAILED":
      return {
        ...state,
        processing: false,
        progress: null,
        previewImages: null,
      };
    case "MAKE_AVATAR_SUCCESS":
      return {
        ...state,
        processing: false,
        progress: null,
        avatarSample: payload.data,
      };
    case "MAKE_AVATAR_FAILED":
      return {
        ...state,
        processing: false,
        progress: null,
        avatarSample: null,
      };
    case "EDIT_PLACEHOLDER":
      newState = {
        processing: true,
        actions: [...state.actions],
        deleted: [...state.deleted],
      };
      newState.actions.push(1);
      return newState;
    case "CLEAR_EDIT_PLACEHOLDER":
      return {
        processing: false,
        actions: [],
        deleted: [...state.deleted],
      };
    case "PLACEHOLDER_PROCESSING":
      let clear_cache = false;
      if (state.clear_cache) {
        clear_cache = true;
      }
      let video_to_update = false;
      if (state.video_to_update) {
        video_to_update = true;
      }
      let deleted_placeholder = [];
      if (state.deleted) {
        deleted_placeholder = state.deleted;
      }
      let placeholderids = [];
      if (state.placeholderids) {
        placeholderids = state.placeholderids;
      }
      let existingPlaceholder = false;
      for (let pl of placeholderids) {
        if (pl.placeholderid == payload.placeholderid) {
          existingPlaceholder = true;
          if (payload.progress && parseInt(payload.progress) > parseInt(pl.progress)) {
            pl.progress = parseInt(payload.progress);
            pl.processing = true;
          }
        }
      }
      if (!existingPlaceholder) {
        placeholderids.push({ placeholderid: payload.placeholderid, progress: payload.progress, processing: true });
      }
      if (state.progress) {
        if (payload.progress && state.progress && parseInt(payload.progress) > parseInt(state.progress)) {
          return {
            ...state,
            processing: true,
            progress: payload.progress,
            deleted: deleted_placeholder,
            placeholderid: payload.placeholderid,
            placeholderids: placeholderids,
            actions: [],
            clear_cache: clear_cache,
            video_to_update: video_to_update,
          };
        } else if (!payload.progress && payload.placeholderid) {
          return {
            ...state,
            processing: true,
            progress: null,
            deleted: deleted_placeholder,
            placeholderid: payload.placeholderid,
            placeholderids: placeholderids,
            actions: [],
            clear_cache: clear_cache,
            video_to_update: video_to_update,
          };
        } else {
          return state;
        }
      } else {
        return {
          ...state,
          processing: true,
          progress: payload?.progress,
          deleted: deleted_placeholder,
          placeholderid: payload?.placeholderid,
          placeholderids: placeholderids,
          actions: [],
          clear_cache: clear_cache,
          video_to_update: video_to_update,
        };
      }
    case "DELETE_PLACEHOLDER_SUCCESS":
      clear_cache = false;
      if (state.clear_cache) {
        clear_cache = true;
      }
      video_to_update = false;
      if (state.video_to_update) {
        video_to_update = true;
      }
      let deleted = [];
      if (state.deleted && state.deleted.length > 0) {
        deleted = state.deleted;
      }
      if (payload?.data) {
        deleted.push(payload.data);
        console.log("new deleted", deleted);
        return {
          processing: false,
          progress: payload?.progress,
          placeholderid: payload.data,
          deleted: deleted,
          actions: [],
          clear_cache: clear_cache,
          video_to_update: video_to_update,
        };
      } else {
        return state;
      }
    case "UPDATE_VIDEO":
      if (state.processing) {
        let newState = { processing: false, progress: null, placeholderids: state.placeholderids };
        let actions = [...state.actions];
        if (actions.length > 0) {
          newState.actions = actions;
          newState.processing = true;
          newState.progress = null;
        } else {
          newState.actions = [];
        }
        if (state.clear_cache) {
          newState.clear_cache = true;
        } else {
          newState.clear_cache = false;
        }
        if (state.video_to_update) {
          newState.video_to_update = true;
        } else {
          newState.video_to_update = false;
        }
        if (state.deleted) {
          newState.deleted = state.deleted;
        }

        return newState;
      } else {
        return state;
      }
    case "EDIT_PLACEHOLDER_SUCCESS":
      let newplaceholderids = [];
      if (state?.placeholderids) {
        for (let pl of state.placeholderids) {
          if (pl.placeholderid != payload.placeholderid) {
            //pl.processing = false;
            //pl.progress = 100;
            newplaceholderids.push(pl);
          }
        }
        state.placeholderids = newplaceholderids;
      }
      return state;
    case "SHOW_UPDATE_VIDEO":
      if (state) {
        state.video_to_update = true;
        state.clear_cache = true;
      }
      return state;
    case "HIDE_UPDATE_VIDEO":
      if (state) {
        state.video_to_update = false;
      }
      return state;
    case "SHOW_UPDATE_VIDEO_CACHED":
      if (state) {
        state.video_to_update = true;
        state.clear_cache = false;
      }
      return state;
    case "LOAD_PROJECT":
      if (state) {
        state.video_to_update = false;
        state.clear_cache = true;
      }
      return state;
    case "WORK_HISTORY_UPDATE_STAGE":
    case "RENDER_VIDEO_SUCCESS":
      if (state) {
        state.video_to_update = false;
        state.clear_cache = true;
      }
      return state;
    default:
      return state;
  }
};
