import React, { useState, useEffect } from "react";
import config from "../../../config";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";

import CardHeader from "@material-ui/core/CardHeader";

import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";

import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";

import { useDispatch, useSelector } from "react-redux";

import { annualToMonthly, formatMonthlyPrice, selectCurrencySymbol } from "./formatters";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export default function PayAsYouGops({ currency = "EUR", country = "FI" }) {
  const accessToken = useSelector((state) => state.user.accessToken);

  const { isLoading, error, data } = useQuery({
    queryKey: ["getPackages", currency],
    queryFn: () =>
      axios
        .post(`${config.socketIo}/pricing/getPackagesForCountry`, { currency, country, accessToken })
        .then((res) => res.data),
  });

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  console.log({ currency });

  const handleBuyClick = async (p) => {
    console.log(p);

    const response = await fetch(`${config.socketIo}/pricing/getPackagePurchaseSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hash: p.hash, accessToken, currency, country }),
    });
    const { url } = await response.json();
    window.location.href = url;
  };

  return (
    <div className="mt-12">
      <div className="flex flex-row w-full">
        <h2 className="w-full text-4xl text-slate-700 font-medium text-center mb-4">
          Buy more credits. Credits are valid for 2 years.
        </h2>
      </div>

      <div className="w-full text-2xl text-slate-400 text-center">
        1 credit = 1 minute of generated video with Virtual Presenter.
      </div>
      <div className="flex flex-row flex-wrap justify-center p-4 items-stretch rounded-lg">
        {data?.packages.map((p) => {
          return (
            <div
              className="flex flex-row max-w-2xl md:max-w-md lg:max-w-sm xl:max-w-19 2xl:max-w-19 justify-center p-0"
              item
              key={p.name}
              container
            >
              <Grid
                item
                className="flex flex-col justify-between w-80 m-3 rounded-lg p-0 m-3"
                component={Card}
                key={p.name}
                style={{
                  margin: 12,
                  // Use flex layout with column direction for components in the card
                  // (CardContent and CardActions)

                  padding: "0px",
                }}
              >
                <div item className="flex flex-col rounded-lg justify-start">
                  <CardHeader
                    title={p.name}
                    subheader={p.subheader}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    action={p.name === "Professional" ? <StarIcon /> : null}
                    className="bg-blue-header "
                    style={{ height: 96 }}
                  />

                  <div className="flex flex-row mt-4  justify-center align-baseline">
                    {p.listPrice && (
                      <Typography
                        component="span"
                        variant="h5"
                        color="textPrimary"
                        style={{
                          color: "gray",
                          fontSize: "1.8em",
                          textDecoration: "line-through",
                          textDecorationColor: "red",
                          textDecorationStyle: "edges",
                          textAlign: "center",
                        }}
                      >
                        <span className="text-gray-500 text-2xl">
                          {selectCurrencySymbol(p)} {formatMonthlyPrice(p.listPrice)}
                        </span>
                      </Typography>
                    )}

                    <ul>
                      <div className="text-black-400 opacity-90 text-4xl ml-2">
                        {selectCurrencySymbol(p)} {formatMonthlyPrice(p.price)}
                      </div>
                    </ul>
                  </div>
                  <ul className="mt-2">
                    <Typography component="li" variant="caption" align="center">
                      {selectCurrencySymbol(p)} {formatMonthlyPrice(p.price / (p.virtualPresenterCredits / 60))} per
                      minute
                    </Typography>
                  </ul>
                </div>
                <CardActions className="mt-4" style={{ paddingBottom: 12, paddingLeft: 12, paddingRight: 12 }}>
                  <button
                    className={"text-white rounded-lg w-full p-3 text-base bg-blue-dark"}
                    fullWidth
                    variant={p.buttonVariant}
                    color="primary"
                    onClick={() => handleBuyClick(p)}
                  >
                    BUY
                  </button>
                </CardActions>
              </Grid>
            </div>
          );
        })}
      </div>
    </div>
  );
}
